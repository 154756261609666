<template>
  <div>
    <v-banner
      single-line
      class="banner-top"
    >
      <v-img :src="'https://www.onechannel.one/wp-content/uploads/2021/01/group6@2x.png'" max-height="120" max-width="120" />

      <template v-slot:actions>
        <v-btn
          v-show="showBtn"
          class="text-capitalize bold-text btn-skip-payment"
          text
          @click="cancel"
        >
          {{ rightText }}
        </v-btn>
      </template>
    </v-banner>
  </div>
</template>

<script>
export default {
  name: 'TopBanner',
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    rightText: {
      type: String,
      default: 'Cancel'
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-top{
    text-align:start;
    border-bottom: 1px solid #1E1E1E;
    background: black!important;
    button{
      color: #E040FB;
      font-weight:bold;
      font-size: 14px;
    }
}
</style>
